<template>
  <div :key="$route.params.slug" class="wrapper wrapper-nopadding page">
    <article v-if="post">
      <div class="wrapper wrapper-mobilepadding">
        <div class="spacer-half">
          <Tags :tags="post.tags"/>
        </div>

        <h1>{{ post.title }}</h1>

        <p>{{ post.description }}</p>

        <hr class="mt">
      </div>

      <div class="wrapper wrapper-mobilepadding">
        <vue-markdown toc class="markdown">
          {{ post.content }}
        </vue-markdown>
      </div>
    </article>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueMarkdown from 'vue-markdown';
import Tags from '@/components/general/Tags.vue';

export default {
  name: 'AppsPage',
  components: {
    VueMarkdown,
    Tags
  },
  computed: {
    ...mapGetters([
      'blogpost'
    ]),
    post: function () {
      return this.blogpost(this.$route.params.slug);
    }
  },
  beforeCreate() {
    this.$store.dispatch('loadBlogpost', this.$route.params.slug);
  },
  watch: {
    $route(to) {
      this.$store.dispatch('loadBlogpost', to.params.slug);
    }
  }
}
</script>